export const getDesignTokens = (mode) =>
  console.log(mode) || {
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: "#00AA00",
              light: "#CBF1CB",
              dark: "#008E00",
              darkest: "#007D00",
            },
            text: {
              primary: "rgb(43, 46, 51)",
              secondary: "#FFFFFF",
              body2: "#2B2E33",
            },
            background: {
              default: "#FFFFFF",

              secondary: "#606060",
            },
            error: {
              main: "#F40028",
            },
            border: {
              main: "#D0D0D1",
              shadow: "rgba(43, 46, 51, 0.1)",
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: "#0D8A72",
              light: "#B4C6C2",
            },
            text: {
              primary: "rgb(242, 242, 242)",
              secondary: "#F2F2F2",
              body2: "#F2F2F2",
            },

            background: {
              default: "#232323",
              secondary: "#323233",
            },
            error: {
              main: "#D33953",
            },
            border: {
              main: "#999999",
              shadow: "#2C2C2C",
            },
          }),
    },
  };
