import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getDesignTokens } from "./utils/getDesignTokens";
import { ruRU } from "@mui/material/locale";
import { CssBaseline } from "@mui/material";
import ColorModeContext from "./context/ColorModeContext";
import Home from "./pages";
import { ruRU as dataGridRu } from "@mui/x-data-grid";
import { SnackbarProvider } from "notistack";

const Login = lazy(() => import("pages/login"));
const ReportsPage = lazy(() => import("pages/reports"));
const NotFound = lazy(() => import("pages/NotFound"));
const MyReports = lazy(() => import("pages/MyReports"));

function App() {
  const isSystemThemeDark = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [mode, setMode] = React.useState("light");
  useEffect(() => {
    isSystemThemeDark() && setMode("dark");
  }, []);

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  let theme = createTheme();

  theme = React.useMemo(
    () =>
      createTheme(
        {
          ...getDesignTokens(mode),
          typography: {
            body1: {
              fontSize: 14,
              fontFamily: "Roboto",

              [theme.breakpoints.up("md")]: {
                fontSize: 16,
              },
            },

            body2: {
              fontSize: 14,
              opacity: 0.4,
              color: theme.palette.text.body2,
              [theme.breakpoints.up("md")]: {
                fontSize: 16,
              },
            },
            body3: {
              fontWeight: 700,
            },

            h1: {
              fontSize: "1.375rem",
              marginBottom: "1.25rem",
              fontWeight: 700,
              [theme.breakpoints.up("md")]: {
                fontSize: "32px",
                marginBottom: "2.5rem",
              },
            },

            h2: {
              fontSize: "18px",
              fontWeight: 500,

              [theme.breakpoints.up("md")]: {
                fontSize: "22px",
              },
            },

            h3: {
              fontSize: "14px",
              fontWeight: 500,

              [theme.breakpoints.up("md")]: {
                fontSize: "18px",
              },
            },

            h4: {
              fontSize: "14px",
              fontWeight: 500,

              [theme.breakpoints.up("md")]: {
                fontSize: "16px",
              },
            },
          },
          components: {
            MuiInputLabel: {
              defaultProps: {
                shrink: true,
              },
            },

            MuiContainer: {
              styleOverrides: {
                root: { maxWidth: "none !important" },
              },
            },
            MuiIconButton: {
              defaultProps: {
                disableRipple: false,
              },
            },
            MuiPaper: {
              defaultProps: {
                square: true,
                elevation: 0,
                sx: { "& *:focus": { outline: "none" } },
              },

              styleOverrides: {
                root: {
                  outline: "none !important",
                },
              },
            },
            MuiDialog: {
              styleOverrides: {
                outline: "none",
              },
            },
            MuiButton: {
              defaultProps: {
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
              },
              styleOverrides: {
                root: {
                  color: theme.palette.text.secondary,
                  "& hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },

                  "& active": {
                    backgroundColor: theme.palette.primary.darkest,
                  },
                  "&.Mui-disabled": {
                    background:
                      theme.palette.mode === "light" ? "#D0D0D1" : "#606060",
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.text.secondary
                        : "#999999",
                  },
                  textTransform: "none",
                },
                outlined: {
                  color: theme.palette.text.primary,
                  boxShadow: "0px 0px 6px rgba(43, 46, 51, 0.1)",
                  borderColor: "transparent",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? theme.palette.background.primary
                      : theme.palette.background.secondary,
                },
                contained: {
                  color: "#FFFF",
                },
              },
            },
          },
        },
        ruRU,
        dataGridRu
      ),
    [
      theme.breakpoints,
      theme.palette.background.primary,
      theme.palette.background.secondary,
      theme.palette.mode,
      theme.palette.primary.dark,
      theme.palette.primary.darkest,
      theme.palette.text.body2,
      theme.palette.text.primary,
      theme.palette.text.secondary,
      mode,
    ]
  );

  theme.components.MuiCard = {
    variants: [
      {
        props: { variant: "primary" },
        style: {
          padding: "2px",

          " :active": {
            border: `2px solid ${theme.palette.primary.main}`,
            padding: "0",
          },

          " :hover": {
            transform: "scale(1.05)",
          },

          " :focus": {
            border: `2px solid ${theme.palette.primary.main}`,
            padding: "0",
          },
        },
      },
    ],
    defaultProps: { square: true },
    styleOverrides: {
      root: {
        boxShadow: "0px 0px 30px rgba(43, 46, 51, 0.15)",
        background:
          theme.palette.mode === "light"
            ? theme.palette.background.primary
            : theme.palette.background.secondary,
      },
    },
  };

  theme.components.MuiClockPicker = {
    styleOverrides: {
      " div": {
        outline: "none !important",
      },
      arrowSwitcher: {
        "& + div > div > div[role=listbox] > span": {
          color: theme.palette.text.primary,
        },
      },
    },
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <CssBaseline />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reports" element={<ReportsPage />} />
              <Route path="/myreports/:id" element={<MyReports />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
